import * as React from 'react'
import { useStaticQuery, graphql, Script } from "gatsby"
import BookingButton from '../components/bookingButton'

import Layout from '../components/layout'
import OffichoursBookingForm from '../components/officehoursBookingForm'
import * as Styles from '../styles/layout.module.scss'

const Officehours
  = ({ pageContext }) => {

    const data = useStaticQuery(graphql`
    query MyOhQuery {
        allAirtableOfficehours(sort: {fields: data___Date}) {
          edges {
            node {
              data {
                Date(formatString: "D.M.YYYY")
                compareDate: Date(formatString: "YYYYMMDD")
                Time
              }
              recordId
            }
          }
        }
      }`)

      var translationContext = { locale:      'de',
                                 defaultMenu: false };
    const currentDate = new Date().toLocaleDateString('en-GB').split('/').reverse().join('')
    return (
      <Layout title="SAP ABAP und HANA Programmiersprechstunde"
        pageContext={pageContext}
        translationContext={translationContext}
      >

        <div class={Styles.singleTopicLayout}>
          <main>
            <div class="entry-content clear" itemprop="text">
              <h1>SAP ABAP und HANA Programmiersprechstunde</h1>
              <p>
                Wir decken in dieser Sprechstunde alle Themen ab, zu denen wir auch Schulungen anbieten. Der Fokus ist entsprechend auf der Programmierung und HANA bzw. SAP BW-Modellierung. Von A wie ABAP bis S wie SQLScript freuen wir uns auf Eure Fragen und Themen.
                Schick uns Deine Fragen am besten schon vorab, damit auch die passenden Experten auf unserer Seite mit dabei sind.
                <strong> Die Teilnahme ist kostenlos</strong>
                . </p>
              <h3>Geplante Sprechstunden</h3>
              <p>Die Veranstaltung findet nur statt, falls sich ein Teilnehmer vorab anmeldet. Deshalb bitte das Anmeldeformular nutzen. Dann können wir Euch auch die Zugangsdaten zur Zoom Session vor der Veranstaltung per E-Mail zuschicken.</p>
              <ul>
                {
                  data.allAirtableOfficehours.edges.filter(edge => edge.node.data.compareDate >= currentDate)
                    .map((edge) => (

                      <li>
                        {edge.node.data.Date + ' um ' + edge.node.data.Time + ' Uhr '}
                      </li>

                    ))
                }
              </ul>
              <BookingButton type="programmiersprechstunde">Sprechstundentermin Buchen</BookingButton>
            </div>
          </main>
        </div>
      </Layout>

    )
  }

export default Officehours